import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function PrintRateUs(props){
    //const langCodes = [ar, am, bg, bn, ca, cs, da, de, el, en, es, et, fa, fi, fil, fr, gu, he, hi, hr, hu, id, it, ja, kn, ko, lt, lv, ml, mr, ms, nl, no, pl, pt_BR, pt_PT, ro, ru, sk, sl, sr, sv, sw, ta, te, th, tr, uk, vi, zh_CN, zh_TW];
    const headers = {
            "ar": "طباعة! - زر الطباعة بواسطة برنامج سوريكاتا",
            "am": "ፕሬንት! - አስተያየት ከሱሪካታ የተሰነደ ቦታ",
            "bg": "Принтирай! - бутон за печат от Suricata Software",
            "bn": "ছাপা! - সুরিকাটা সফটওয়্যারের মাধ্যমে প্রিন্ট বোতাম",
            "ca": "Imprimeix! - botó d'impressió per Suricata Software",
            "cs": "Tisk! - tlačítko tisku od Suricata Software",
            "da": "Print! - printknap af Suricata Software",
            "de": "Drucken! - Druckknopf von Suricata Software",
            "el": "Εκτύπωση! - κουμπί εκτύπωσης από τη Suricata Software",
            "en": "Print! - print button by Suricata software",
            "es": "¡Imprimir! - botón de impresión por Suricata Software",
            "et": "Prindi! - printimisnupp Suricata tarkvarast",
            "fa": "چاپ! - دکمه چاپ توسط نرم‌افزار سوریکاتا",
            "fi": "Tulosta! - tulostuspainike Suricata Softwarelta",
            "fil": "Mag-print! - print button mula sa Suricata software",
            "fr": "Imprimer! - bouton d'impression par Suricata Software",
            "gu": "છાપો! - સુરિકાટા સોફ્ટવેર દ્વારા છાપણ બટન",
            "he": "הדפס! - כפתור ההדפסה על ידי תוכנה של Suricata",
            "hi": "प्रिंट! - सूरिकाटा सॉफ़्टवेयर द्वारा प्रिंट बटन",
            "hr": "Ispiši! - gumb za ispis od Suricata Software",
            "hu": "Nyomtatás! - nyomtatógomb a Suricata szoftverből",
            "id": "Cetak! - tombol cetak oleh Perangkat Lunak Suricata",
            "it": "Stampa! - pulsante di stampa di Suricata Software",
            "ja": "印刷！- Suricataソフトウェアによる印刷ボタン",
            "kn": "ಮುದ್ರಿಸಿ! - ಸುರಿಕಾಟಾ ಸಾಫ್ಟ್‌ವೇರ್‌ನಿಂದ ಮುದ್ರಣ ಬಟನ್",
            "ko": "인쇄! - Suricata 소프트웨어의 인쇄 버튼",
            "lt": "Spausdinti! - spausdintuvo mygtukas iš Suricata programinės įrangos",
            "lv": "Drukāt! - drukas pogu no Suricata programmatūras",
            "ml": "പ്രിന്റുചെയ്യുക! - സുരികാട്ട സോഫ്റ്റ്‌വെയരിൽ നിന്നുള്ള പ്രിന്റ് ബട്ടൺ",
            "mr": "प्रिंट! - सूरिकाटा सॉफ्टवेअर द्वारे प्रिंट बटण",
            "ms": "Cetak! - butang cetak oleh Perisian Suricata",
            "nl": "Afdrukken! - afdrukknop door Suricata Software",
            "no": "Skriv ut! - utskriftsknapp av Suricata programvare",
            "pl": "Drukuj! - przycisk drukowania od Suricata Software",
            "pt_BR": "Imprimir! - botão de impressão pela Suricata Software",
            "pt_PT": "Imprimir! - botão de impressão pela Suricata Software",
            "ro": "Tipărește! - buton de imprimare de la Suricata Software",
            "ru": "Печать! - кнопка печати от Suricata Software",
            "sk": "Tlačiť! - tlačidlo tlače od spoločnosti Suricata Software",
            "sl": "Natisni! - gumb za tiskanje podjetja Suricata Software",
            "sr": "Штампај! - дугме за штампу од компаније Suricata Software",
            "sv": "Skriv ut! - utskriftsknapp av Suricata Software",
            "sw": "Chapisha! - kifungo cha uchapishaji kutoka kwa Programu ya Suricata",
            "ta": "அச்சு! - செயலி மூலம் அச்சு பட்டன்",
            "te": "ముద్రించండి! - సూరికాటా సాఫ్ట్‌వేర్ ద్వారా ముద్రణ బటన్",
            "th": "พิมพ์! - ปุ่มพิมพ์โดยซอฟต์แวร์ Suricata",
            "tr": "Yazdır! - Suricata Yazılımı tarafından yazdırma düğmesi",
            "uk": "Друкувати! - кнопка друку від Suricata Software",
            "vi": "In! - nút in từ Suricata Software",
            "zh_CN": "打印！- 由Suricata软件提供的打印按钮",
            "zh_TW": "列印！- 由Suricata軟體提供的列印按鈕"
          };
      const text = {
        "ar": { p1: "إذا كنت سعيدًا بزر الطباعة لدينا", p2: "يرجى النظر", p3: "في تقييم الامتداد." },
        "am": { p1: "እስክትታይ ሲሆን", p2: "እንኳን ደናልኩ", p3: "በተመሳሳይ እቅድ እንዴት ይዘትን ማስወገድ።" },
        "bg": { p1: "Ако сте доволни от бутона за печат", p2: "моля, обмислете", p3: "да оцените разширението." },
        "bn": { p1: "আপনি আমাদের ছাপার বাটনে খুশি হলে", p2: "অনুগ্রহ করে বিবেচনা করুন", p3: "এক্সটেনশন রেটিং।" },
        "ca": { p1: "Si esteu contents amb el nostre botó d'impressió", p2: "si us plau, consideri", p3: "valorar l'extensió." },
        "cs": { p1: "Pokud jste spokojeni s naším tlačítkem tisku", p2: "prosím, zvažte", p3: "hodnocení rozšíření." },
        "da": { p1: "Hvis du er tilfreds med vores udskriftsknap", p2: "venligst overvej", p3: "at vurdere udvidelsen." },
        "de": { p1: "Wenn Sie mit unserem Druckknopf zufrieden sind", p2: "bitten wir Sie,", p3: "die Erweiterung zu bewerten." },
        "el": { p1: "Εάν είστε ευχαριστημένοι με το κουμπί εκτύπωσης μας", p2: "σας παρακαλούμε να εξετάσετε", p3: "την αξιολόγηση της επέκτασης." },
        "en": { p1: "If you are happy with our print button", p2: "please consider", p3: "rating the extension." },
        "es": { p1: "Si estás contento con nuestro botón de impresión", p2: "por favor, considera", p3: "calificar la extensión." },
        "et": { p1: "Kui olete meie printimisnupuga rahul", p2: "palun kaalu", p3: "laajenduse hindamine." },
        "fa": { p1: "اگر با دکمه چاپ ما راضی هستید", p2: "لطفاً در نظر بگیرید", p3: "امتیاز دادن به افزونه." },
        "fi": { p1: "Jos olet tyytyväinen tulostuspainikkeeseemme", p2: "ota huomioon", p3: "laajennuksen arvioiminen." },
        "fil": { p1: "Kung masaya ka sa aming button ng print", p2: "pag-isipan", p3: "pag-rate ng extension." },
        "fr": { p1: "Si vous êtes satisfait de notre bouton d'impression", p2: "veuillez envisager", p3: "de noter l'extension." },
        "gu": { p1: "જો તમે અમારા પ્રિન્ટ બટનથી ખુશ છો", p2: "વિનંતી આપે છે", p3: "વિસ્તારનું રેટિંગ કરો." },
        "he": { p1: "אם אתה מרוצה עם כפתור ההדפסה שלנו", p2: "בבקשה שקול", p3: "לדרג את ההרחבה." },
        "hi": { p1: "यदि आप हमारे प्रिंट बटन से खुश हैं", p2: "कृपया विचार करें", p3: "एक्सटेंशन की रेटिंग।" },
        "hr": { p1: "Ako ste zadovoljni našim gumbom za ispis", p2: "molimo razmislite", p3: "o ocjenjivanju proširenja." },
        "hu": { p1: "Ha elégedett vagy a nyomtatás gombunkkal", p2: "kérjük, vegye figyelembe", p3: "az bővítmény értékelését." },
        "id": { p1: "Jika Anda puas dengan tombol cetak kami", p2: "mohon pertimbangkan", p3: "menilai perpanjangan." },
        "it": { p1: "Se sei soddisfatto del nostro pulsante di stampa", p2: "considera", p3: "valutare l'estensione." },
        "ja": { p1: "当社の印刷ボタンにご満足いただける場合は", p2: "ぜひご検討ください", p3: "拡張機能の評価をお願いします。" },
        "kn": { p1: "ನಮ್ಮ ಪ್ರಿಂಟ್ ಬಟನ್ ನಿಮಗೆ ಸಂತೋಷವಾಗಿದ್ದರೆ", p2: "ದಯವಿಟ್ಟು ಲಕ್ಷಿಸಿ", p3: "ವಿಸ್ತರಣೆಯನ್ನು ರೇಟ್ ಮಾಡಿ." },
        "ko": { p1: "만족스러우시면 당사의 인쇄 버튼을", p2: "고려해주세요", p3: "확장 프로그램을 평가해 주세요." },
        "lt": { p1: "Jei esate patenkinti mūsų spausdinimo mygtuku", p2: "prašome apsvarstyti", p3: "plėtinio įvertinimą." },
        "lv": { p1: "Ja esat apmierināts ar mūsu drukāšanas pogu", p2: "lūdzu, apsveriet", p3: "paplašinājuma novērtēšanu." },
        "ml": { p1: "നിങ്ങൾക്ക് ഞങ്ങളുടെ പ്രിന്റ് ബട്ടൺ മനസ്സിലാകുമ്പോൾ", p2: "ദയവായി പരിഗണിക്കുക", p3: "വിപരീതം റേറ്റിംഗ് ചെയ്യുക." },
        "mr": { p1: "आपल्याला आमच्या प्रिंट बटणासह संतुष्ट असल्यास", p2: "कृपया विचारा", p3: "विस्ताराची मूल्यांकन करा." },
        "ms": { p1: "Jika anda gembira dengan butang cetak kami", p2: "sila pertimbangkan", p3: "menilai sambungan." },
        "nl": { p1: "Als u tevreden bent met onze afdrukknop", p2: "overweeg dan", p3: "de extensie te beoordelen." },
        "no": { p1: "Hvis du er fornøyd med vår utskriftsknapp", p2: "vurder", p3: "vurdering av utvidelsen." },
        "pl": { p1: "Jeśli jesteś zadowolony z naszego przycisku drukowania", p2: "prosimy o rozważenie", p3: "ocenę rozszerzenia." },
        "pt_BR": { p1: "Se você está feliz com nosso botão de impressão", p2: "por favor, considere", p3: "avaliar a extensão." },
        "pt_PT": { p1: "Se estiver satisfeito com o nosso botão de impressão", p2: "por favor, considere", p3: "avaliar a extensão." },
        "ro": { p1: "Dacă sunteți mulțumit de butonul nostru de imprimare", p2: "vă rugăm să luați în considerare", p3: "evaluarea extensiei." },
        "ru": { p1: "Если вы довольны нашей кнопкой печати", p2: "пожалуйста, рассмотрите", p3: "оценку расширения." },
        "sk": { p1: "Ak ste spokojní s našim tlačovým tlačidlom", p2: "zvážte prosím", p3: "hodnotenie rozšírenia." },
        "sl": { p1: "Če ste zadovoljni z našim gumbom za tiskanje", p2: "prosimo, upoštevajte", p3: "ocenjevanje razširitve." },
        "sr": { p1: "Ако сте задовољни нашим дугметом за штампу", p2: "молимо вас да размислите", p3: "о оцењивању проширења." },
        "sv": { p1: "Om du är nöjd med vår utskriftsknapp", p2: "vänligen överväga", p3: "att betygsätta tillägget." },
        "sw": { p1: "Ikiwa una furaha na kifungo chetu cha uchapishaji", p2: "tutafakari tafadhali", p3: "kutoa kiwango cha upanuzi." },
        "ta": { p1: "உங்களுக்கு எங்கள் அச்சு பட்டனுடன் மகிழ்ச்சி இருந்தால்", p2: "தயவுசெய்து கருத்து செய்யவும்", p3: "நீள மதிப்பீடு செய்யுங்கள்." },
        "te": { p1: "మీరు మా ప్రింట్ బటన్‌తో సంతోషంగా ఉన్నట్లు", p2: "దయచేసి పరిగణించండి", p3: "పొందించే పొందుపరికరంను రేటింగ్ చేయండి." },
        "th": { p1: "หากคุณพอใจกับปุ่มพิมพ์ของเรา", p2: "โปรดพิจารณา", p3: "การให้คะแนนส่วนเสริม" },
        "tr": { p1: "Eğer yazdırma düğmemizden memnunsanız", p2: "lütfen düşünün", p3: "uzantıyı değerlendirme." },
        "uk": { p1: "Якщо ви задоволені нашою кнопкою друку", p2: "будь ласка, розгляньте", p3: "оцінка розширення." },
        "vi": { p1: "Nếu bạn hài lòng với nút in của chúng tôi", p2: "vui lòng xem xét", p3: "đánh giá tiện ích." },
        "zh_CN": { p1: "如果您对我们的打印按钮感到满意", p2: "请考虑", p3: "评价扩展功能。" },
        "zh_TW": { p1: "如果您對我們的列印按鈕感到滿意", p2: "請考慮", p3: "評價擴展。" }
    };
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/print-print-button/mhcepidlpdgdfcliacjbmiomdiilmhij";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/print-print-button/packoppfoicmljjmmjnhiigdeianmaog"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang]; 
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let enLink = "../print-rate_us/"+browser+"/en";
        return(
        <div  style={{backgroundImage:"URL(/../assets/print-review.jpg)", width:"1024px", height:"1024px"}}>
            <NavLink to="/en/contact"><img src="/../assets/email-icon.svg" style={{float:"right", width:"48px", margin:"16px"}} /></NavLink>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"48px"}}>
<br/>
<h1>{header}</h1>
<h2>
    <span>{p1} <br />{p2} <a href={url} style={{padding:"4px",color:"orange"}}>{p3}</a></span>
</h2>
</div>
        </div>
        )
}