import Limitations from "../Limitations.js";
import PinExtension from "../PinExtension.js";
import SupportUs from "../SupportUs.js";
import Header from "../../Header.js";
import { Link } from "react-router-dom";

export default function NoSwipe(props){
    return(
        <>
        <Header title="No Swipe" x="35"/>
        <div className="article">
        Disable trackpad two fingers navigation.<br /><br />

        Prevent browsers' back & forward gestures.<br />
Stops horizontal scrolling from triggering page navigation.<br /><br />

You can select which sites will have the swipe gestures disabled using the extension popup (by default the swipe gesture is disabled in all sites).<br />
 
Follow these steps to disable touchpad navigation:<br />
1) Pin extensions to the toolbar.<br />
2) Press the extension icon.<br />
3) Select mode of operation.<br />
4) Set the site to a list. <br /><br />

If you are unable to stop navigation by trackpad, please <Link to={"../contact"}>contact us</Link>.<br /><br />
Available for: <a href="https://chromewebstore.google.com/detail/no-swipe-disable-swipe/nalejidoccmgcmjjdcioafcjbilcjmlc">Chrome</a>,&nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/no-swipe-disable-swipe/geodenjgpngnkeibfikhcnmmjoonlden">Edge</a> and &nbsp;
 <a href="https://addons.mozilla.org/en-US/firefox/addon/no-swipe-disable-swipe/">Firefox</a>.
        </div>
            <Limitations />
            <PinExtension />
            <SupportUs />
        </>
        )
}