
export default function OperaUsers(props){
    
    return(
        <>
<div id="opera-additional-us" className="main-title">
<p>
Opera users additional instructions
</p>
</div>
<div className="article">
    <p>
    Opera browser by default restricts extensions on search engines sites.
    </p>
If you want to use the extension in web search engines, press the cube icon and choose "manage extensions". 
Find the extension and check the box near the options "allow access to search page results".
</div>
        </>
        )
}