import './App.css';

import {Route, Routes, Navigate} from "react-router-dom";
import Home from './en/Home';
import ImageDownloader from './en/Image Downloader/ImageDownloader';
import Print from './en/print/Print';
import PrintWithoutImages from './en/print without images/PrintWithoutImages';
import NoSwipe from './en/No Swipe/NoSwipe';
import NavBar from './en/NavBar';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import UninstallSurvey from './en/UninstallSurvey';
import UninstallSurveyLegacy from './en/UninstallSurveyLegacy';
import PrintReview from './en/print/PrintReview';
import Contact from './en/Contact';
import PostContact from './en/PostContact';
import PrintRateUs from './PrintRateUs';
import ImageDownloaderRateUs from './ImageDownloaderRateUs';
import NoSwipeRateUs from './NoSwipeRateUs';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyjxNuqnIzLcvuHpID_QSWyBzMonm7Fuo",
  authDomain: "suricata-44947.firebaseapp.com",
  projectId: "suricata-44947",
  storageBucket: "suricata-44947.appspot.com",
  messagingSenderId: "933632047434",
  appId: "1:933632047434:web:d54eee5cc30e08b9a169b3",
  measurementId: "G-Y0M3W47Q4M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function App() {
  return (
    <div className="App">
      <div id="main-container">
      <NavBar />
      <Routes>
        <Route path='/print-rate_us/:browser/:lang' element={<PrintRateUs />} />
        <Route path='/image_downloader-rate_us/:browser/:lang' element={<ImageDownloaderRateUs />} />
        <Route path='/' element={<Navigate to='/en/home' />} />
        <Route path='/en/home' element={<Home />} />
        <Route path='/contact' element={<Navigate to='/en/contact' />} />
        <Route path='/en/contact' element={<Contact db={db} />} />
        <Route path='/en/post-contact' element={<PostContact />} />
        <Route path='/en/uninstall_survey/:subject' element={< UninstallSurvey db={db}/>} />
        <Route path='/en/print-review/:browser' element={< PrintReview/>} />
        <Route path='/print' element={<Navigate to='/en/print' />} />
        <Route path='/en/print' element={<Print />} />
        <Route path='/en/print-uninstall_survey' element={<UninstallSurveyLegacy subject="Print" db={db}/>} />
        <Route path='/en/print-review-opera' element={<review/>} />
        <Route path='/print_without_images' element={<Navigate to='/en/print_without_images' />} />
        <Route path='/en/print_without_images' element={<PrintWithoutImages />} />
        <Route path='/en/print_without_images-uninstall_survey' element={<UninstallSurveyLegacy subject="PrintWithoutImages" db={db}/>} />
        <Route path='/no_swipe' element={<Navigate to='/en/no_swipe' />} />
        <Route path='/en/no_swipe' element={<NoSwipe />} />
        <Route path='/no-swipe-rate_us/:browser/:lang' element={<NoSwipeRateUs />} />
        <Route path='/en/no_swipe-uninstall_survey' element={<UninstallSurveyLegacy subject="NoSwipe" db={db}/>} />
        <Route path='/image_downloader' element={<Navigate to='/en/image_downloader' />} />
        <Route path='/en/image_downloader' element={<ImageDownloader />} />
        <Route path='/en/image_downloader-uninstall_survey' element={<UninstallSurveyLegacy subject="ImageDownloader" db={db}/>} />
      </Routes>
    </div>
    <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
    </div>
  );
}

export default App;
