import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function NoSwipeRateUs(props){

    const appNameTrans = {
        "ar": {
            "appName": {"message": "No Swipe - تعطيل السحب"},
            "appDesc": {"message": "تعطيل التنقل بالإصبعين على لوحة اللمس."}
        },
        "am": {
            "appName": {"message": "No Swipe - ትክክለኛ ያግኝ"},
            "appDesc": {"message": "የአካባቢ እርምጃ ያደርጋል ሁለት አንቀላፍ የካርታ በመጠቀም።"}
        },
        "bg": {
            "appName": {"message": "No Swipe - Деактивиране на плъзгането"},
            "appDesc": {"message": "Деактивиране на навигацията с два пръста върху тъчпада."}
        },
        "bn": {
            "appName": {"message": "No Swipe - স্যুইপ অক্ষম করুন"},
            "appDesc": {"message": "টাচপ্যাডের দুটি আঙুল নেভিগেশন নিষ্ক্রিয় করুন।"}
        },
        "ca": {
            "appName": {"message": "No Swipe - Desactivar el lliscament"},
            "appDesc": {"message": "Desactiva la navegació amb dos dits al touchpad."}
        },
        "cs": {
            "appName": {"message": "No Swipe - Zakázat posouvání"},
            "appDesc": {"message": "Zakázat navigaci dvěma prsty na touchpadu."}
        },
        "da": {
            "appName": {"message": "No Swipe - Deaktiver swipe"},
            "appDesc": {"message": "Deaktiver tofingernavigation på touchpad."}
        },
        "de": {
            "appName": {"message": "No Swipe - Wischen deaktivieren"},
            "appDesc": {"message": "Deaktiviere die Touchpad-Zwei-Finger-Navigation."}
        },
        "el": {
            "appName": {"message": "No Swipe - Απενεργοποίηση σύρσιμου"},
            "appDesc": {"message": "Απενεργοποίηση πλοήγησης με δύο δάχτυλα στο touchpad."}
        },
        "en": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_AU": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_GB": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_US": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "es": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "es_419": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "et": {
            "appName": {"message": "No Swipe - Keela libistamine"},
            "appDesc": {"message": "Keela puuteplaatide navigeerimine kahe sõrmega."}
        },
        "fa": {
            "appName": {"message": "No Swipe - غیرفعال کردن کشیدن"},
            "appDesc": {"message": "غیرفعال کردن دو انگشت ناوبری در تاچ‌پد."}
        },
        "fi": {
            "appName": {"message": "No Swipe - Poista pyyhkäisy"},
            "appDesc": {"message": "Poista kosketuslevyn kahden sormen navigointi."}
        },
        "fil": {
            "appName": {"message": "No Swipe - Huwag Galawin ang Swipe"},
            "appDesc": {"message": "I-disable ang daliring navigasyon sa touchpad."}
        },
        "fr": {
            "appName": {"message": "No Swipe - Désactiver le balayage"},
            "appDesc": {"message": "Désactivez la navigation à deux doigts sur le pavé tactile."}
        },
        "gu": {
            "appName": {"message": "No Swipe - સ્વાઈપ અક્ષમ કરો"},
            "appDesc": {"message": "ટચપેડ દોડા નેવિગેશન નિષ્ક્રિય કરો બીજી ટેકલીનો સાંધાન."}
        },
        "he": {
            "appName": {"message": "No Swipe - השבת החלקה"},
            "appDesc": {"message": "מבטל ניווט בעזרת שתי אצבעות על משטח מגע."}
        },
        "hi": {
            "appName": {"message": "No Swipe - स्वाइप को अक्षम करें"},
            "appDesc": {"message": "टचपैड पर दो उंगलियों की नेविगेशन को निष्क्रिय करें।"}
        },
        "hr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogući navigaciju dva prsta na touchpadu."}
        },
        "hu": {
            "appName": {"message": "No Swipe - Pörges tiltása"},
            "appDesc": {"message": "A touchpad kéttakarós navigációjának letiltása."}
        },
        "id": {
            "appName": {"message": "No Swipe - Menonaktifkan Swipe"},
            "appDesc": {"message": "Menonaktifkan navigasi dua jari di touchpad."}
        },
        "it": {
            "appName": {"message": "No Swipe - Disabilita lo Swipe"},
            "appDesc": {"message": "Disabilita la navigazione a due dita sul touchpad."}
        },
        "ja": {
            "appName": {"message": "No Swipe - スワイプを無効にする"},
            "appDesc": {"message": "タッチパッドの2本指ナビゲーションを無効にします。"}
        },
        "kn": {
            "appName": {"message": "No Swipe - ಸ್ವೈಪ್ ನಿಲ್ಲಿಸಿ"},
            "appDesc": {"message": "ಟಚ್‌ಪ್ಯಾಡ್‌ನಲ್ಲಿ ಎರಡು ಬೆರಳು ನ್ಯಾವಿಗೇಷನ್‌ನನ್ನು ನಿಲ್ಲಿಸಿ."}
        },
        "ko": {
            "appName": {"message": "No Swipe - 스와이프 비활성화"},
            "appDesc": {"message": "터치 패드 두 손가락 네비게이션 비활성화."}
        },
        "lt": {
            "appName": {"message": "No Swipe - Išjungti Swipe"},
            "appDesc": {"message": "Išjungti navigaciją dviem pirštais ant touchpad."}
        },
        "lv": {
            "appName": {"message": "No Swipe - Deaktivēt Swipe"},
            "appDesc": {"message": "Deaktivēt divpirkstu navigāciju uz pieskāriena paneli."}
        },
        "ml": {
            "appName": {"message": "No Swipe - സ്വൈപ് അടയ്ക്കുക"},
            "appDesc": {"message": "ടച്ച്‌പാഡിലെ രണ്ട് അങ്കുളകളുടെ നാവിഗേഷൻ അടയ്ക്കുക."}
        },
        "mr": {
            "appName": {"message": "No Swipe - स्वाईप अक्षम करा"},
            "appDesc": {"message": "टचपॅडवर दोन बोटांचे नेव्हिगेशन अक्षम करा."}
        },
        "ms": {
            "appName": {"message": "No Swipe - Matikan Swipe"},
            "appDesc": {"message": "Matikan navigasi dua jari di touchpad."}
        },
        "nl": {
            "appName": {"message": "No Swipe - Swipe uitschakelen"},
            "appDesc": {"message": "Schakel de touchpad-navigatie met twee vingers uit."}
        },
        "no": {
            "appName": {"message": "No Swipe - Deaktiver sveiping"},
            "appDesc": {"message": "Deaktiver berøringsskjermens to-finger-navigasjon."}
        },
        "pl": {
            "appName": {"message": "No Swipe - Wyłącz Swipe"},
            "appDesc": {"message": "Wyłącz nawigację dwoma palcami na touchpadzie."}
        },
        "pt_BR": {
            "appName": {"message": "No Swipe - Desativar Swipe"},
            "appDesc": {"message": "Desativar navegação de dois dedos no touchpad."}
        },
        "pt_PT": {
            "appName": {"message": "No Swipe - Desativar o Swipe"},
            "appDesc": {"message": "Desativar a navegação de dois dedos no touchpad."}
        },
        "ro": {
            "appName": {"message": "No Swipe - Dezactivați Swipe"},
            "appDesc": {"message": "Dezactivați navigarea cu două degete pe touchpad."}
        },
        "ru": {
            "appName": {"message": "No Swipe - Отключить свайп"},
            "appDesc": {"message": "Отключите навигацию двумя пальцами на тачпаде."}
        },
        "sk": {
            "appName": {"message": "No Swipe - Zakázať Swipe"},
            "appDesc": {"message": "Zakázať navigáciu dvoma prstami na touchpad."}
        },
        "sl": {
            "appName": {"message": "No Swipe - Onemogoči povleci"},
            "appDesc": {"message": "Onemogoči navigacijo z dvema prstoma na sledilni ploščici."}
        },
        "sr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogućite navigaciju dva prsta na touchpadu."}
        },
        "sv": {
            "appName": {"message": "No Swipe - Inaktivera Swipe"},
            "appDesc": {"message": "Inaktivera tvåfingernavigering på styrplattan."}
        },
        "sw": {
            "appName": {"message": "No Swipe - Lemaza Swipe"},
            "appDesc": {"message": "Lemaza usafiri wa vidole viwili kwenye kifaa cha kugusa."}
        },
        "ta": {
            "appName": {"message": "No Swipe - ஸ்வைப் முடக்கு"},
            "appDesc": {"message": "டச்சிப்பேடில் இரண்டு விரல் நாவிகேசனை முடக்கு."}
        },
        "te": {
            "appName": {"message": "No Swipe - స్వైప్‌ను అమలు చేయి"},
            "appDesc": {"message": "టచ్‌ప్యాడ్‌లో రెండు వంటి నావిగేషన్‌ను నిల్వ చేయండి."}
        },
        "th": {
            "appName": {"message": "No Swipe - ปิดการใช้งาน Swipe"},
            "appDesc": {"message": "ปิดการนำทางด้วยนิ้วสองบนแผงสัมผัส"}
        },
        "tr": {
            "appName": {"message": "No Swipe - Swipe'yı Devre Dışı Bırak"},
            "appDesc": {"message": "Dokunmatik yüzeyde iki parmakla gezinmeyi devre dışı bırakın."}
        },
        "uk": {
            "appName": {"message": "No Swipe - Вимкніть Swipe"},
            "appDesc": {"message": "Вимкніть навігацію двома пальцями на тачпаді."}
        },
        "vi": {
            "appName": {"message": "No Swipe - Vô hiệu hóa Swipe"},
            "appDesc": {"message": "Vô hiệu hóa điều hướng hai ngón tay trên bàn di chuột."}
        },
        "zh_CN": {
            "appName": {"message": "No Swipe - 禁用滑动"},
            "appDesc": {"message": "禁用触摸板的双指导航。"}
        },
        "zh_TW": {
            "appName": {"message": "No Swipe - 停用滑動"},
            "appDesc": {"message": "停用觸控板的雙指導覽。"}
        }
    }
    const headers = {
    
        "en": ["Your extension No Swipe was updated to the latest version."]
        
    };
    
    const text = {
        "en": { 
            "p1": "If you feel the No Swipe extension has value, you can support its maintenance by paying for the developer's coffee.", 
            "p2": "Not in a position to donate? That's OK, you can still help:", 
            "p3": "Assist in spreading the extension: Find a forum question regarding selectively disabling navigation and post a link to the extension. Or inform someone you know who has this issue about the extension.",
            "p4": "Report a bug or suggest an improvement",
            "p5": "We improve the image capture mechanism - we now have less misses.",
            "p6": ""
        }
       
    }
            
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang];
    let appName =appNameTrans[lang]["appName"]["message"];
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let p4 = text[lang]['p4'];
    let p5 = text[lang]['p5'];
    let p6 = text[lang]['p6'];
    let enLink = "../no_swipe-rate_us/"+browser+"/en";
        return(
            <>
            <div>
                <img src="/assets/NoSwipe128B.png" height={"64px"}/> <h1 style={{paddingTop:"24px",color:"white",display:"inline-block"}}>{appName}</h1>
</div>
<div>
<h1 style={{paddingTop:"24px",color:"white"}}>{header}</h1>

</div>
        <div  style={{backgroundImage:"URL(/../assets/meercat-download-images.jpeg)", width:"1024px", height:"1024px"}}>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"24px,8px,24px,8px"}}>
<h2 style={{backgroundColor:"rgb(0,0,0,0.6)",color:"white",padding:'2px,8px, 2px'}}>
    <div style={{paddingLeft:'8px', paddingTop:'8px',paddingBottom:'2px'}}><p style={{marginTop:'0'}}>
    {p1}&nbsp;<a href=""><img src="/assets/paypal-icon.png"/></a></p>
        {p2}<br/>
        <ol>
            <li>
                {p3}
                </li>
                <li>
                <NavLink to="/en/contact">{p4}</NavLink>.
                </li>
            </ol>
        </div>
</h2>
</div>
        </div>
        </>
        )
}


