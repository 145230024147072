import { useParams } from "react-router-dom";

export default function PrintReview(props){
    const { browser } = useParams();
    let url ="https://chromewebstore.google.com/detail/print-print-button/mhcepidlpdgdfcliacjbmiomdiilmhij";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/print-print-button/packoppfoicmljjmmjnhiigdeianmaog"
    }else if(browser=="opera"){
        url="opera"
    }
        return(
        <div  style={{backgroundImage:"URL(/../assets/print-review.jpg)", width:"1024px", height:"1024px"}}>
            <div style={{color:"white",margin:"48px"}}>
<br/>
<h1>Print! - print button by Suricata software</h1>
<h2>
    <span>If you are happy with our print button <br />please consider <a href={url} style={{padding:"4px",color:"orange"}}>rating the extension.</a></span>
</h2>
</div>

        </div>
        )
}