import { useState } from "react";
import { useParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore"; 

export default function UninstallSurvey(props){
//change meanfull to usefull
/*add More than 90 trees been planted so far with aftet nbsp
fix and isolete design
addon hover design*/

document.title = props.subject+" - unistall survey";
document.body.style = "background-image: url('/assets/forest.jpg'); background-repeat: no-repeat;  background-attachment: fixed; background-size: 100% 100%;";
const [submited, setSubmited] = useState(false);
const [body, setBody] = useState("");

const submit = async (event) =>{
    event.preventDefault();
    const docRef = await setDoc(doc(props.db, "emails",Date.now().toString()), {
        message: {
            subject: props.subject,
            html: body,
        }
    });
    setSubmited(true);
}
if(submited){ return(<div className="u-s-page-header" style={{padding: "24px", margin: "48px", backgroundColor: "rgba(255, 255, 255, 0.9)"}}>Thank you for your feedback :-{")"}</div>)}else{
    return(
<div style={{backgroundColor: "rgba(111, 111, 111, 0.9)", marginTop:"64px", padding: "32px 16px 16px 16px", borderRadius: "16px"}}>
    <div className="u-s-page-header" style={{textAlign:"start", marginLeft: "16px"}}>You uninstalled the extension</div>
    <div className="u-s-main-title" style={{marginLeft: "16px", fontFamily: "Helvetica" , fontSize: "2em"}}>
        <p>We want to do better - Tell us what went wrong.</p>
    </div>
    <span style={{color:"white"}}> 
        &nbsp;
    </span>

    <div className="u-s-container" style={{backgroundColor: "transparent", padding: "1em"}}>
        <form id="form" onSubmit={submit}>
            <textarea id="content" name="content" placeholder="I uninstall the extension because..."
                style={{height:"150px", width: "100%", padding: "12px", border: "1px solid #ccc", borderRadius: "4px", boxSizing: "border-box", marginTtop: "6px", marginBottom: "16px", resize: "vertical"}} onChange={(e) => setBody(e.target.value)}></textarea>
            <input style={{ color: "white", padding: "12px 20px", border: "none", borderRadius: "4px", cursor: "pointer"}} type="submit" value="Submit" />
        </form>
    </div>
</div>   
    )
}
}
