
export default function PinExtension(props){
    
    return(
        <>
<div id="pin-extension-icon" className="main-title">
<p>
    Pin extension icon
</p>
</div>
<div className="article">  
<p>
    <b>In Chrome</b> Press the puzzle icon next to the address bar. Then press the pin icon next to the extension name.
    <br /><b>In Edge</b> Press the puzzle icon next to the address bar. Then press the eye icon next to the extension name.
    <br /><b>In Opera</b> Press the cube icon next to the address bar. Then press the pin icon next to the extension name.
    <br /><b>In Firefox</b> Right-click next to the address bar and choose "Customize Toolbar". Then drag the extension icon to the toolbar.
</p>
</div>
        </>
        )
}