import { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { emailPass } from "../Utils.js";
import { useNavigate } from "react-router-dom";

export default function Contact(props){
    document.title = "Suricata Software - Contact";
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [body, setBody] = useState("");
    const navigate = useNavigate();
    const submit = async (event) =>{
        event.preventDefault();
        if(emailPass(email)){
            const docRef = await addDoc(collection(props.db, "emails"), {
                message: {
                    subject: subject,
                    html: email +"\nname: "+name+"\n\n"+body,
                }
            });
            navigate("/en/post-contact");
        }
    }

    return (
        <>
            <div className="page-header"><p style={{color:"white"}}>We want to hear from you...</p></div>
            <div className="main-title"><p>Contact us</p></div>
            <div className="container">

                <form onSubmit={submit}>

                    <label htmlFor="subject">Subject</label>
                    <select id="subject" name="subject" onChange={(e) => setSubject(e.target.value)}>
                        <option value="feature-request">Feature request</option>
                        <option value="bug-report">Bug report</option>
                        <option value="help">Help me</option>
                        <option value="other">Other</option>
                    </select>

                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" placeholder="Your name" onChange={(e) => setName(e.target.value)} />

                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} />

                    <label htmlFor="content">What's on your mind?</label>
                    <textarea id="c-content" name="content" placeholder="Hi..." onChange={(e) => setBody(e.target.value)} ></textarea>

                    <input type="submit" value="Submit" />

                </form>
                
            </div>
        </>
    )
}