import Limitations from "../Limitations.js";
import PinExtension from "../PinExtension.js";
import SupportUs from "../SupportUs.js";

export default function PrintWithoutImages(props){
    return(
        <>
            <Limitations />
            <PinExtension />
            <SupportUs />        
        </>
        )
}