
export default function RateUs(props){
    
    return(
        <>
<div id="support-us" className="main-title">
<p>
    Support us
</p>
</div>
<div className="article">  
If you like our extension we will appreciate it if you spread the word. 
Please consider sharing and rating the extension. </div>
        </>
        )
}