export default function Home(props){
    document.title = "Suricata software";
    return(
        <>
                <div className="page-header"><p>Suricata software</p></div>
                <div className="article">
                Welcome to Suricata Software, where innovation meets customization in the world of web browsing! 
        </div>
                        <div className="main-title"><p>Who we are</p></div>

        <div className="article"><p>
At Suricata Software, we specialize in crafting bespoke web browser extensions tailored to your unique needs. Whether you're a small business looking to enhance user experience or a large enterprise seeking to streamline workflows, our team of seasoned developers is dedicated to bringing your vision to life.

Why choose Suricata Software for your custom web browser extensions? Here's what sets us apart:</p>
<p>
1. Tailored Solutions: We understand that one size does not fit all. That's why we work closely with you to understand your requirements and create personalized browser extensions that align perfectly with your goals.
</p>
<p>
2. Seamless Integration: Our extensions seamlessly integrate with popular web browsers, ensuring a smooth user experience without any hiccups or compatibility issues.
</p>
<p>
3. Enhanced Functionality: From improving productivity with time-saving features to enhancing security with advanced measures, our extensions are designed to add value to your browsing experience.
</p>
<p>
4. Expertise and Experience: With years of experience in the software industry, our team possesses the expertise and technical know-how to tackle even the most complex challenges, delivering top-notch solutions every time.
</p>
<p>
5. Ongoing Support: Our commitment to customer satisfaction doesn't end after deployment. We provide continuous support and updates to ensure that your browser extensions remain efficient, reliable, and up-to-date.
</p>

Discover the power of customized browsing experiences with Suricata Software. Get in touch with us today to discuss your project and take the first step towards unlocking a world of possibilities on the web!
        </div>
        </>
        )
}