export default function Limitations(props){
    
    return(
        <>
        <div id="Limitations" className="main-title"><p>Limitations</p></div>
        <div className="article">
            (All) extensions <mark><b>do not work</b> on <b>add-ons download sites</b> such as the chrome web store and <b>non-URL sites</b> such as the <b>first page</b> when you open a new tab.</mark>
        </div>
        </>
        )
}